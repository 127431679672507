import React, { useEffect, useState } from "react";
import DeleteModal from "components/common/Modals/DeleteModal";
import {
  BulkDeletePlanning,
  DeletePlanningById,
  FilterPlanning,
} from "lib/PlanningLib";
import { ModalRoot, ModalService } from "components/common/Modal";
import CheckboxTable from "components/common/CheckboxTable";
import Filter from "components/common/Filter";
import EditMany from "components/common/EditMany";
import DropdownComponent from "components/common/Dropdown";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { usePropertyContextCheck } from "context/SidebarContext/PropertyContextCheck";

const BatchEditing = ({
  deleteItem,
  handleChangeAction,
  handleSortClick,
  sort,
}) => {
  const [checkedRows, setCheckedRows] = useState(() => []);
  const [data, setData] = useState();
  const [reload, setReload] = useState(false);

  const [filterValues, setFilterValues] = useState({});
  const { currentTab } = usePropertyContextCheck();
  const { t } = useTranslation();

  useEffect(() => {
    handlePageChange(1, 100);
  }, [reload, currentTab]);

  useEffect(() => {
    if (deleteItem) {
      if (checkedRows.length > 0) {
        ModalService.open(DeleteModal, {
          type: "Maintence Item",
          handleDelete: async () => {
            await handleDeleteItems(checkedRows);
          },
          handleClose: () => {
            handleChangeAction(null);
            // setReload(true);
          },
        });
      }
    }
  }, [deleteItem]);
  const handleCheckRows = (id) => {
    if (id === "all" || id === "none") {
      if (id === "all") {
        setCheckedRows(data?.data.map((item) => item._id));
      } else {
        setCheckedRows([]);
      }
    } else {
      const findId = checkedRows.find((item) => item === id);

      if (!findId) {
        setCheckedRows([...checkedRows, id]);
        return;
      }
      setCheckedRows([...checkedRows.filter((item) => item !== id)]);
      return;
    }
  };
  const handleDeleteItems = async (checkedRows) => {
    if (checkedRows.length > 0) {
      // for (let index = 0; index < checkedRows.length; index++) {
      //   const item = checkedRows[index];
      //   await DeletePlanningById(item);
      // }
      await BulkDeletePlanning(checkedRows);
      setCheckedRows([]);
      setReload(true);
    }
  };

  const handleFindClick = async () => {
    setCheckedRows([]);
    // perform find logic using filterValues
    const res = await FilterPlanning(
      {
        body: JSON.stringify(filterValues),
      },
      "?limit=100"
    );
    const newData = await res.json();
    setData(newData);
    setCheckedRows([]);
  };

  const handlePageChange = async (currentPage, limit, id, order) => {
    const { order: sortOrder, id: sortId } = sort;

    const res = await FilterPlanning(
      {
        body: JSON.stringify(filterValues),
      },
      `?page=${currentPage}${limit ? `&limit=${limit}` : ""}&sort=${
        (id || order || sortId || sortOrder) && true
      }&order=${order || sortOrder}&id=${id || sortId}`
    );
    const newData = await res.json();
    setCheckedRows([]);
    setData(newData);
    setReload(false);
  };

  const filteredCheckedRows = data?.data;
  const reducedCheckedRows = filteredCheckedRows?.length > 0 && {
    ...filteredCheckedRows[0],
  };

  filteredCheckedRows?.forEach((obj) => {
    Object.keys(reducedCheckedRows).forEach((key) => {
      if (reducedCheckedRows[key] !== obj[key]) {
        reducedCheckedRows[key] = undefined;
      }
    });
  });

  const handleSelectItems = (id) => {
    if (id === "check_rows") {
      const newData = data?.data || [];
      const newCheckedRows = newData.filter(
        (item) => !checkedRows.includes(item._id)
      );
      setData({ ...data, data: newCheckedRows });
    } else if (id === "uncheck_rows") {
      const newData = data?.data || [];
      const newUncheckedRows = newData.filter((item) =>
        checkedRows.includes(item._id)
      );
      setData({ ...data, data: newUncheckedRows });
    }
  };

  const handleCheckRow = (id) => {
    if (id === "all") {
      if (checkedRows?.length === data?.data?.length) {
        setCheckedRows([]);
        if (handleCheckRows) handleCheckRows("none");
      } else {
        setCheckedRows(data?.data?.map((item) => item.id));
        if (handleCheckRows) handleCheckRows("all");
      }
    } else {
      if (checkedRows.includes(id)) {
        setCheckedRows(checkedRows.filter((checkedId) => checkedId !== id));
      } else {
        setCheckedRows([...checkedRows, id]);
      }
      if (handleCheckRows) handleCheckRows(id);
    }
  };

  const batchEditingTableColumn = [
    {
      name: (
        <div className="form-check">
          <input
            onChange={() => {
              handleCheckRow("all");
            }}
            className="form-check-input"
            type="checkbox"
            value=""
            checked={data?.data?.every((item) =>
              checkedRows?.includes(item?._id)
            )}
          />
        </div>
      ),
      cell: (row, index, column, id) => {
        return (
          <div>
            <input
              onChange={() => handleCheckRow(row._id)}
              className="form-check-input"
              type="checkbox"
              value=""
              checked={checkedRows?.includes(row._id)}
              id={row._id}
              style={{ marginLeft: "0rem" }}
            />
          </div>
        );
      },
    },
    {
      name: t("common.pages.property"),
      cell: (row, index, column, id) => {
        return <span>{row.property_name} </span>;
      },
      sortable: true,
      selector: "property_name",
      width: "160px",
    },
    {
      name: t("common.pages.building"),
      cell: (row, index, column, id) => {
        return <span>{row.building_name} </span>;
      },
      selector: "building_name",
      sortable: true,
      width: "170px",
    },
    {
      name: "System",
      cell: (row, index, column, id) => {
        return <span> {row.u_system} </span>;
      },
      // width: "80px",
      sortable: true,
      selector: "u_system",
    },
    {
      name: t("planning_page.article_code"),
      cell: (row, index, column, id) => {
        return <span> {row.article} </span>;
      },
      selector: "article",
      // width: "130px",
      sortable: true,
    },
    {
      name: t("planning_page.start_year"),
      cell: (row, index, column, id) => {
        return <span> {row.start_year} </span>;
      },
      sortable: true,
      selector: "start_year",
      // width: "123px",
    },
    {
      name: t("planning_page.technical_life"),
      cell: (row, index, column, id) => {
        return <span> {row.technical_life} </span>;
      },
      selector: "technical_life",
      // width: "199px",
      sortable: true,
    },
    {
      name: t("planning_page.previous_year"),
      cell: (row, index, column, id) => {
        return <span> {row.previous_year} </span>;
      },
      sortable: true,
      // width: "80px",
      selector: "previous_year",
    },
    {
      name: t("planning_page.unit"),
      cell: (row, index, column, id) => {
        return <span> {row.unit || 0} </span>;
      },
      sortable: true,
      selector: "unit",
      // width: "70px",
    },

    {
      name: "Unit Cost",
      cell: (row, index, column, id) => {
        return <span>{row.price_per_unit}</span>;
      },
      sortable: true,
      selector: "price_per_unit",
      // width: "70px",
    },

    {
      name: t("planning_page.quantity"),
      cell: (row, index, column, id) => {
        return <span> {row.quantity || 0} </span>;
      },
      sortable: true,
      selector: "quantity",
      // width: "125px",
    },
    {
      name: t("planning_page.total_cost"),
      cell: (row, index, column, id) => {
        const cost =
          row.total_cost || (row.quantity || 0) * (row.price_per_unit || 0);
        return <span>{cost.toLocaleString()}</span>;
      },
      sortable: true,
      selector: (row) => {
        return (
          row.total_cost || (row.quantity || 0) * (row.price_per_unit || 0)
        );
      },
    },
  ];

  return (
    <>
      {deleteItem && <ModalRoot />}
      <Filter
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFindClick={handleFindClick}
      />
      <EditMany
        reducedCheckedRows={reducedCheckedRows}
        checkedRows={checkedRows}
        data={data}
        setCheckedRows={setCheckedRows}
        handleDataSubmit={setData}
        setData={setData}
      />

      <div className="batch_edit_remove_btn">
        <DropdownComponent
          nameReset
          style={{ width: "100%" }}
          items={[
            {
              text: t("common.pages.checked_rows"),
              id: "check_rows",
              handleClick: (id) => handleSelectItems(id),
            },
            {
              text: t("common.pages.unchecked_rows"),
              id: "uncheck_rows",
              handleClick: (id) => handleSelectItems(id),
            },
          ]}
          name={t("common.pages.remove")}
          icon="expand_more"
          className="btn btn-secondary text-white rounded-pill mx-4 px-4 py-2 d-flex jusitfy-content-center align-items-center"
        ></DropdownComponent>
      </div>

      {/* <CheckboxTable
        defaultCheckedRows={checkedRows}
        handleSortClick={async (w, x, y, z) => {
          await handleSortClick(w, x, y, filterValues);
          handlePageChange(
            data.pagination.currentPage,
            data.pagination?.pageSize,
            w,
            x,
            y
          );
        }}
        pagination={data?.pagination || {}}
        handlePage={handlePageChange}
        headings={[
          { text: "Property", key: "property", sort: false },
          { text: "Building", key: "building", sort: false },
          { text: "System", key: "u_system" },
          { text: "Article Code", key: "article" },
          { text: "Start Year", key: "start_year" },
          { text: "Technical Life", key: "technical_life" },
          { text: "Previous Year", key: "previous_year" },
          { text: "Unit", key: "unit" },
          { text: "Unit Cost", key: "price_per_unit" },
          { text: "Quantity", key: "quantity" },
          { text: "Total Cost", key: "total_cost", sort: false },
        ]}
        data={
          data?.data?.map((item) => ({
            id: item._id,
            property: item.property_name,
            building: item.building_name,
            u_system: item.u_system,
            article: item.article,
            start_year: item.start_year,
            technical_life: item.technical_life,
            previous_year: item.previous_year,
            unit: item.unit,
            price_per_unit: item.price_per_unit,
            quantity: item.quantity || 0,
            total_cost: (
              (item.quantity || 0) * item.price_per_unit
            ).toLocaleString(),
          })) || []
        }
        handleCheckRows={handleCheckRows}
      /> */}
      <div className="mt-5">
        <DataTable
          data={data?.data}
          columns={batchEditingTableColumn}
          noDataComponent={t("common.pages.There are no records to display")}
          highlightOnHover
          responsive
          pagination
          className="create_edit_table"
          paginationComponentOptions={{
            rowsPerPageText: t("planning_page.rows_per_page"),
          }}
        />
      </div>
    </>
  );
};

export default BatchEditing;
